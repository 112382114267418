import { IPartner } from '@utils/types';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Partner = ({ title, image, link }: IPartner) => {
	const gatsbyImage = getImage(image.childImageSharp.gatsbyImageData);

	return (
		<div className="card partner-card">
			{gatsbyImage && (
				<div className="partner-card__image">
					<GatsbyImage
						image={gatsbyImage}
						alt={title}
						objectFit="contain"
					/>
				</div>
			)}
			<div className="partner-card__content">
				<div className="partner-card-actions">
					<span className="partner-card__button button--more is-inverted is-clean"> </span>
				</div>
			</div>
			<Link to={link} className="link-coverer partner-card__link">
				View partner
			</Link>
		</div>
	);
};

export default Partner;
