// i18next-extract-mark-ns-start about-us-technical-partners

import { LINKS } from '@utils/constants';
import { IPartnerFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import Partner from '@components/Partner';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const TechnicalPartnerPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__partners" layout="wide">
				<h2><Trans i18nkey="PartnerTitle">Realis Simulation is proud to work with the following technical partners</Trans>:</h2>
				<Deck>
					{data.partners.edges.map(
						({ node }: { node: IPartnerFrontmatter }, i: number) => (
							<Partner
								key={i}
								title={node.frontmatter.title}
								image={node.frontmatter.image}
								link={`${LINKS.ABOUT_PARTNERS}${node.fields.slug}`}
							/>
						)
					)}
				</Deck>
			</PageSection>
		</Page>
	);
};

export default TechnicalPartnerPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-technical-partners", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/technical-partners/Realis-Simulation_TechnicalPartners.jpg" }) {
		...imageBreaker
	}
	partners: allMarkdownRemark(
		filter: { 
			fileAbsolutePath: { regex: "/src/partners/" } 
			fields: { language: { eq: $language } }
		}
	) {
		edges {
			node {
				fields {
					slug
				}
				frontmatter {
					title
					image {
						...imageStandard
					}
				}
			}
		}
	}
}
`;